@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@font-face {
  font-family: "HK Grotesk";
  src: url("../assets/fonts/HKGrotesk-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "HK Grotesk";
  src: url("../assets/fonts/HKGrotesk-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HK Grotesk";
  src: url("../assets/fonts/HKGrotesk-SemiBold.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "HK Grotesk";
  src: url("../assets/fonts/HKGrotesk-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}
